import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../services/notification.service';
import { Observable, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';


@Injectable({
    providedIn: 'root'
})
export class OktaGroupGuard {
    private readonly notificationService = inject(NotificationService);
    private readonly translate = inject(TranslateService);
    private readonly authService = inject(AuthService);



    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.authService.isGroupMember(route.data['expectedGroup'] || []).pipe(
            tap(p => p || this.notificationService.showMsg(this.translate.instant('route.noPermission'), 'error'))
        );
    }
}
